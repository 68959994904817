
  
  .filter-button {
    background-color: transparent;
    border-left: 1px solid rgb(151, 151, 151);
    text-align: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  
  
  .submit-button {
    background-color: #007BFF; /* Customize this color */
    color: white;
    border-radius: 5px;
  }
  
  .submit-button:hover {
    background-color: #0056b3; /* Darker shade for hover */
  }
  

  .number-input::-webkit-inner-spin-button,
.number-input::-webkit-outer-spin-button {
  -webkit-appearance: none; /* For Chrome, Safari, and Opera */
  margin: 0; /* Remove default margin */
}

.number-input[type=number] {
  -moz-appearance: textfield; /* For Firefox */
}


.relative {
    position: relative;
  }
  
  .badge {
    position: absolute;
    top: -5px; /* Adjust as needed */
    right: -10px; /* Adjust as needed */
    background-color: red; /* Change to your preferred color */
    color: white; /* Text color */
    border-radius: 12px;
    padding: 2px 6px; /* Adjust padding */
    font-size: 0.75rem; /* Adjust font size */
    font-weight: bold;
  }
  

  @media (max-width: 768px) {
    #bedsBathModal, #propertyTypeModal {
      width: 90%; /* Full width on smaller screens */
      left: 5%; /* Centering it */
    }
  }
  